import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTotalPrice } from "../../app/redux/cartStore/cartSlice";
import { toggleStatus } from "../../app/redux/store/orderSlice/orderSlice";

const PayYooKassa = ({ data, onCreateOrder }) => {
  const paymentFormRef = useRef(null);
  const checkoutRef = useRef(null);
  const allPrice = useSelector(selectTotalPrice);
  const dispatch = useDispatch();
  // const [firstLoad, setFirstLoad] = useState(true);

  // useEffect(() => {
  //   if (!firstLoad) {
  //     // Вызываем onCreateOrder только после монтирования компонента
  //     // onCreateOrder();
  //     const timeoutId = setTimeout(() => {
  //       onCreateOrder();
  //     }, 2000);
  //     // очищаем таймаут
  //     return () => clearTimeout(timeoutId);
  //   } else {
  //     // Если это первая загрузка, устанавливаем firstLoad в false
  //     setFirstLoad(false);
  //   }
  // }, [allPrice]);

  useEffect(() => {
    const renderWidget = async () => {
      try {
        if (data?.confirmation?.confirmation_token) {
          // Находим элемент по id и удаляем, если он существует
          if (checkoutRef.current) {
            await checkoutRef.current.destroy();
            console.log("Previous widget destroyed");
          }
          checkoutRef.current = new window.YooMoneyCheckoutWidget({
            //   const wid = new window.YooMoneyCheckoutWidget({
            confirmation_token: data.confirmation.confirmation_token,
            // return_url: "https://cvetut64.ru/checkout",
            error_callback: function (error) {
              console.error("Widget Initialization Error:", error);
            },
          });

          checkoutRef.current.on("success", () => {
            // Код, который нужно выполнить после успешной оплаты.
            dispatch(toggleStatus({ statusInfo: "оплачено" }));
            // Вызываем onCreateOrder для создания заказа
            console.log("оплата успешно завершена");

            // Удаление инициализированного виджета
            checkoutRef.current.destroy();
          });
          checkoutRef.current.on("modal_close", () => {
            //Код, который нужно выполнить после закрытия всплывающего окна.
            dispatch(toggleStatus({ statusInfo: "отмена" }));
            // Вызываем onCreateOrder для создания заказа
            console.log("оплата отменена пользователем");

            // Удаление инициализированного виджета
            // checkoutRef.current.destroy();
          });

          checkoutRef.current.on("fail", () => {
            // Код, который нужно выполнить после неудачной оплаты.
            console.log("оплата не состоялась");
            // Удаление инициализированного виджета
            checkoutRef.current.destroy();
          });

          await checkoutRef.current.render(paymentFormRef.current.id);
          //   await wid.render(paymentFormRef.current.id);
          //   checkout.destroy();

          console.error("Сколько раз платежжжжжж?????");
        }
      } catch (error) {
        console.error("Error rendering YooKassa widget:", error);
      }
    };

    renderWidget();

    return () => {
      if (checkoutRef.current) {
        checkoutRef.current.destroy();
        console.log("Widget destroyed");
      }
    };
  }, [allPrice]);

  return (
    // <div>
    <div id="payment-form" ref={paymentFormRef}></div>
    // </div>
  );
};

export default PayYooKassa;
