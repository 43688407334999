import React, { useEffect, useState } from "react";
import style from "./formOrderBy.module.scss";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import {
  resetCart,
  selectAllItems,
  selectTotalPrice,
  toggleDeliveryInCart,
} from "../../app/redux/cartStore/cartSlice";
import { confirmPayKassa, createOrder } from "../../app/http/orderApi";
import DeliveryDateTime from "../../features/DeliveryDateTime/DeliveryDateTime";
import NumberFormat, { PatternFormat } from "react-number-format";
// import TestRod from "../testRod/TestRod";
// import { styled } from "@mui/material/styles";
import {
  selectOrderData,
  selectOrderError,
  selectOrderStatus,
  // selectShowPayWidget,
  toggleStatus,
} from "../../app/redux/store/orderSlice/orderSlice";
import PayYooKassa from "../../widgets/payYooKassa/PayYooKassa";
import Modal from "../../shared/ui/modal/Modal";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import YandexDelivery from "../../features/YandexMaps/YandexDelivery";
// import Suggest from "../../features/YandexMaps/Suggest";
import { loadTarifFromZone } from "../../app/http/delivery";
// import { setPrice } from "../../app/redux/store/deliveryStore/deliverySlice";
import { useNavigate } from "react-router-dom";

const FormOrderBy = () => {
  const orderStatus = useSelector(selectOrderStatus);
  // const showPayWidget = useSelector(selectShowPayWidget); // Используем новый селектор
  const orderError = useSelector(selectOrderError);
  const orderData = useSelector(selectOrderData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allItems = useSelector(selectAllItems);
  const allPrice = useSelector(selectTotalPrice);
  const priceDelivery = useSelector((state) => state.delivery?.price);
  const [deliveryOption, setDeliveryOption] = useState("Самовывоз"); // Default to "Доставка"
  const [priceAndDelivery, setpriceAndDelivery] = useState(null); // Default to "Доставка"
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isAddPhoneValid, setIsAddPhoneValid] = useState(true);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comments: "",
    about: "",
    phone: "",
    address: "",
    zone: "",
    deliveryTime: "",
    deliveryOption: "Самовывоз",
    availability: true,
    orderPrice: 0,
    priceDelivery: 0,
    FullOrderPrice: 0,
    variablePay: "",
    addPhone: "",
    anonim: false,
    kv: null,
  });

  const [modalActive, setModalActive] = useState(false);

  const phoneRegex = /\d/g;
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) || email === "";
  };

  const handleEmailBlur = (event) => {
    const { value } = event.target;
    setIsEmailValid(validateEmail(value));
  };

  const handleCheckboxChangeColor = (event) => {
    setShowAddPhone(event.target.checked);
  };

  // console.log(orderData, "прием платежааааааааааааааааааааааа");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (deliveryOption === "Самовывоз") {
        // console.log(formData, "что имеем??????");
        setFormData((prevData) => {
          const updatedFormData = {
            ...prevData,
            orderPrice: allPrice,
            FullOrderPrice: allPrice,
            priceDelivery: 0,
            address: "Мясницкая ул., 54, Саратов",
            variablePay: "Онлайн оплата",
          };
          // console.log(updatedFormData, "что имеем после обновления??????");
          dispatch(createOrder({ order: updatedFormData, allItems }));
          return updatedFormData;
        });
        setModalActive(true);
      } else {
        setFormData((prevData) => {
          const updatedFormData = {
            ...prevData,
            FullOrderPrice: +prevData.orderPrice + +priceDelivery,
            variablePay: "Онлайн оплата",
          };
          // console.log(updatedFormData, "что имеем после обновления??????");
          dispatch(createOrder({ order: updatedFormData, allItems }));
          return updatedFormData;
        });
        setModalActive(true);
      }
    } catch (error) {}
  };
  const handleSubmitPostPay = async (e) => {
    e.preventDefault();

    try {
      setFormData((prevData) => {
        const updatedFormData = {
          ...prevData,
          orderPrice: allPrice,
          FullOrderPrice: allPrice,
          priceDelivery: 0,
          address: "Мясницкая ул., 54, Саратов",
          variablePay: "Оплата на месте",
        };
        dispatch(createOrder({ order: updatedFormData, allItems }));
        dispatch(toggleStatus({ statusInfo: "постоплата" }));

        return updatedFormData;
      });
    } catch (error) {
      // Обработка ошибок
    }
  };

  const handleTimeChange = (selectedDate) => {
    // console.log(selectedDate, "что со временем?");
    setFormData((prevData) => ({
      ...prevData,
      deliveryTime: selectedDate,
    }));
    if (formData.address !== "Мясницкая ул., 54, Саратов") {
      dispatch(loadTarifFromZone({ zone: formData.zone, time: selectedDate }));
    }
  };

  // Универсальный handleChange
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevData) => {
      if (type === "checkbox") {
        return {
          ...prevData,
          [name]: checked,
        };
      } else if (name === "phone" || name === "addPhone") {
        const numericValue = value.replace(/\D/g, "");
        return {
          ...prevData,
          [name]: numericValue,
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const handleDeliveryOptionChange = (option) => {
    setDeliveryOption(option);
    setFormData((prevData) => ({
      ...prevData,
      deliveryOption: option,
    }));
    // dispatch(toggleDeliveryInCart(option));
  };

  const handleZoneChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      address: event.address,
      zone: event.zone,
      availability: event.availability,
    }));

    if (event.availability) {
      dispatch(
        loadTarifFromZone({
          zone: event.zone,
          time: formData.deliveryTime,
          availability: event.availability,
        })
      );
    }

    // }
  };

  // const isPhoneValid =
  //   formData?.phone && formData.phone.replace(/\D/g, "").length === 10;

  useEffect(() => {
    if (priceDelivery) {
      setpriceAndDelivery(+priceDelivery + +allPrice);
      setFormData((prevData) => ({
        ...prevData,
        priceDelivery: priceDelivery,
      }));
    }
  }, [priceDelivery, deliveryOption, allPrice]);
  useEffect(() => {
    if (orderStatus === "постоплата" || orderStatus === "оплачено") {
      navigate("/confirm");
      dispatch(resetCart());
    }
    if (orderStatus === "оплачено") {
      dispatch(confirmPayKassa({ data: orderData?.description }));
    }
  }, [orderStatus]);

  useEffect(() => {
    if (allPrice) {
      setpriceAndDelivery(allPrice);
      setFormData((prevData) => ({
        ...prevData,
        orderPrice: allPrice,
        FullOrderPrice: allPrice,
        address: "Мясницкая ул., 54, Саратов",
      }));
    }
  }, []);
  useEffect(() => {
    const cleanedPhone = formData.phone.replace(/\D/g, "");
    setIsPhoneValid(cleanedPhone.length === 10);

    const cleanedAddPhone = formData.addPhone.replace(/\D/g, "");
    setIsAddPhoneValid(!showAddPhone || cleanedAddPhone.length === 10);
  }, [formData.phone, formData.addPhone, showAddPhone]);

  return (
    <>
      <div className={style.wrapper}>
        {orderStatus === "pending" && orderData ? (
          // <PayYooKassa data={orderData} onCreateOrder={handleCreateOrder} />
          <Modal visible={modalActive} setVisible={setModalActive}>
            <PayYooKassa data={orderData} />
          </Modal>
        ) : null}

        {/* <StyledForm> */}
        <Box
          autoComplete="off"
          component="form"
          // my={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
            flexWrap: "wrap",
            alignItems: "center",
            // padding: "0.5rem",
            justifyContent: "center",
            "& .MuiTextField-root": {
              m: 0.5,
              width: "58ch",
              "@media (max-width: 768px)": {
                width: "30ch", // Change width for screens up to 768px
              },
            },
          }}
          noValidate>
          <div className={style.dostavka}>
            <TextField
              label="Имя"
              type="text"
              name="name"
              value={formData.name}
              onInput={handleChange}
            />
            <TextField
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!isEmailValid}
              onBlur={handleEmailBlur}
              helperText={!isEmailValid && "Введите корректный email"}
            />

            <TextField
              id="outlined-multiline-static"
              label="Открытка"
              minRows={3}
              name="comments"
              placeholder="Тут можно написать поздравление и пожелания"
              multiline
              // rows={4}
              value={formData.comments}
              onChange={handleChange}
              inputProps={{ maxLength: 200 }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Комментарий"
              minRows={3}
              name="about"
              placeholder="Тут можно написать поздравление и пожелания"
              multiline
              // rows={4}
              value={formData.about}
              onChange={handleChange}
              inputProps={{ maxLength: 300 }}
            />
            <PatternFormat
              format="+7 (###) ###-##-##"
              allowEmptyFormatting
              mask="_"
              type="text"
              name="phone"
              value={formData.phone}
              placeholder="+7 (999) 111-22-22"
              customInput={TextField}
              autoComplete="off"
              onValueChange={({ value }) =>
                handleChange({ target: { name: "phone", value } })
              }
              label="Телефон"
              error={!isPhoneValid}
              helperText={!isPhoneValid && "Введите корректный номер телефона"}
            />

            <FormControlLabel
              control={
                <Checkbox
                  // defaultChecked
                  color="secondary"
                  onChange={handleCheckboxChangeColor}
                />
              }
              label="Получатель другой человек"
            />
            {showAddPhone && (
              <PatternFormat
                format="+7 (###) ###-##-##"
                allowEmptyFormatting
                mask="_"
                type="text"
                name="addPhone"
                value={formData.addPhone}
                placeholder="+7 (999) 111-22-22"
                customInput={TextField}
                autoComplete="off"
                onValueChange={({ value }) =>
                  handleChange({ target: { name: "addPhone", value } })
                }
                label="Телефон другого человека"
                error={!isAddPhoneValid}
                helperText={
                  !isAddPhoneValid && "Введите корректный номер телефона"
                }
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  // defaultChecked
                  color="secondary"
                  name="anonim"
                  onChange={handleChange}
                />
              }
              label="Отправить анонимно"
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group">
                  <FormControlLabel
                    // value="female"
                    name="deliveryOption"
                    value="Самовывоз"
                    checked={deliveryOption === "Самовывоз"}
                    onChange={() => handleDeliveryOptionChange("Самовывоз")}
                    control={<Radio />}
                    label="Самовывоз"
                  />
                  <FormControlLabel
                    type="radio"
                    name="deliveryOption"
                    value="Доставка"
                    checked={deliveryOption === "Доставка"}
                    onChange={() => handleDeliveryOptionChange("Доставка")}
                    control={<Radio />}
                    label="Доставка"
                  />
                  {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>

              <DeliveryDateTime
                deliveryOption={deliveryOption}
                onTimeChange={handleTimeChange}
              />
            </div>

            <div style={{ position: "relative", minHeight: "20px" }}>
              {deliveryOption === "Самовывоз" ? (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Адрес самовывоза:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      Мясницкая ул., 54, Саратов
                    </span>{" "}
                  </div>
                  <p>Самовывоз: бесплатно</p>
                </>
              ) : deliveryOption === "Доставка" &&
                formData.address === "Мясницкая ул., 54, Саратов" ? (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Выбран адрес:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      введите адрес на карте
                    </span>{" "}
                  </div>
                  <p> Стоимость доставки :</p>
                </>
              ) : (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Выбран адрес:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      {formData.address}
                    </span>{" "}
                  </div>
                  <TextField
                    label="номер квартиры"
                    type="number"
                    name="kv"
                    value={formData.kv}
                    onInput={handleChange}
                  />

                  {formData && !formData.availability ? (
                    <p className={style.error}>
                      доставку необходимо согласовать с менеджером или
                      попробуйте другой адрес
                    </p>
                  ) : (
                    <p>
                      {" "}
                      Стоимость доставки :{" "}
                      <span
                        style={{
                          color: "green",
                          fontWeight: "bold",
                        }}>
                        {priceDelivery}
                      </span>{" "}
                      руб{" "}
                    </p>
                  )}
                </>
              )}
            </div>
            <p style={{ margin: "0" }}>
              {" "}
              Итого к оплате :{" "}
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                }}>
                {deliveryOption === "Самовывоз" ? allPrice : priceAndDelivery}
              </span>{" "}
              руб{" "}
            </p>
          </div>

          <div className={style.btns}>
            {!formData.availability && deliveryOption !== "Самовывоз" ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                связаться с менеджером
              </Button>
            ) : deliveryOption !== "Самовывоз" ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  formData.address === "Мясницкая ул., 54, Саратов" ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплатить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплатить
              </Button>
            )}
            {deliveryOption === "Самовывоз" && (
              <Button
                variant="contained"
                color="success"
                type="submit"
                onClick={handleSubmitPostPay}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплата при получении
              </Button>
            )}
          </div>
        </Box>
        {deliveryOption === "Самовывоз" ? (
          <YandexDelivery
            placemark={deliveryOption}
            addressCurrent={() => {}}
          />
        ) : (
          <YandexDelivery
            delivery={"доставка"}
            addressCurrent={handleZoneChange}
            time={formData.deliveryTime === "" ? null : formData.deliveryTime}
          />
        )}
      </div>
    </>
  );
};

export default FormOrderBy;
